@import '../themes';

.nb-scrollbar{
  &::-webkit-scrollbar-track {
    background: nb-theme(scrollbar-background-color);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: nb-theme(sidebar-scrollbar-color);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: nb-theme(sidebar-scrollbar-color);
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
}
