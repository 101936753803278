//@import 'prismjs/themes/prism-okaidia.css';
@import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
@import 'prismjs/plugins/line-highlight/prism-line-highlight.css';

@mixin ngx-markdown() {
  blockquote {
    border-left: 3px solid rgba(0, 0, 0, 0.21);
    padding-left: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
}
