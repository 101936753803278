@use 'themes' as *;

@use '@nebular/theme/styles/globals' as *;
@use "scss/typography" as  *;
@use "scss/layout" as *;
@use "scss/ngx-markdown" as *;
/* Importing Bootstrap SCSS file. */
@use '~bootstrap/scss/bootstrap-grid' as *;
@use '~bootstrap/scss/bootstrap-reboot' as *;
@use '~bootstrap/scss/bootstrap-utilities' as *;
@use '~katex/dist/katex' as *;
@include nb-install() {
  @include nb-theme-global();
  @include app-layout();
  @include app-typography();
  @include ngx-markdown();
}

:host ::ng-deep {
  .ck .ck-content {
    min-height: 200px !important;
  }
}
/* You can add global styles to this file, and also import other style files */

nb-select .select-button {
  min-width: 6rem!important;
}

/* site-specific css goes here */
/* Personalizações */
table.inout {
  margin: 1em;
  border-collapse: collapse;
  border-spacing: 0;
  width: 90%;
  height: 100%;
  border: 1px double #b3b3b3;
  text-align: right;
  font-family: monospace;
}

table.inout th {
  font-weight: 700;
  background: #e5e5e5;
}

table.inout td,
table.inout th {
  min-width: 2em;
  padding: 0.4em;
  vertical-align: top;
  border: 1px solid #bfbfbf;
}

img.imgstatiment{
  max-width: 40%;
  float: right;
  padding: 1px;
}
