@use '@nebular/theme/styles/global/breakpoints' as *;
@use 'scrollbar' as *;
@mixin app-layout() {
  nb-tag{
    white-space: nowrap !important;
  }

  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }

  }
  .dialog-content {
    width: 95vw;
    max-height: 85vh;
    margin: auto;
    overflow-y: auto;
    @extend .nb-scrollbar;
  }
  .layout-content {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    .layout-content {
      max-width: 1350px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (min-width: 768px) {
    .dialog-content {
      width: 600px;

    }
  }
  .dialog-content-large {
    width: 95vw !important;
    max-height: 90vh;
    margin: auto;
    overflow-y: auto;
    @extend .nb-scrollbar;
  }
  //@media screen and (min-width: 576px) {
  //  .dialog-content {
  //    width: 90vw !important;
  //  }
  //}
  @media screen and (min-width: 768px) {
    .dialog-content-large {
      width: 80vw !important;
    }
  }
  @media screen and (min-width: 992px) {
    .dialog-content-large {
      width: 75vw !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .dialog-content-large {
      width: 65vw !important;
    }
  }
  @media screen and (min-width: 1400px) {
    .dialog-content-large {
      width: 55vw !important;
    }
  }
  nb-spinner{
    z-index: 998 !important;
    //position: relative !important;
    //padding-top: 15px !important;
    //padding-bottom: 15px !important;
  }
}
